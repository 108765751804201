@use './../abstracts/variables' as variables;
.login-image{
    object-fit: cover;
    object-position: center left;
}

.version-container{
    span{
        background-color: rgba( map-get(variables.$color-list, B800) , .20);
        padding: .5rem;
        border-radius: .5rem;
        color: map-get(variables.$color-list, B100);
    }
    @media (min-width: 768px){
        position: absolute;
        bottom: 2rem;
        text-align: center;
        padding: .5rem;
        width: 100%;
    }
}