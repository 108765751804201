@use './../abstracts/variables' as variables;
@use './../abstracts/mixins' as mixins;
.video-capture-container{
    @include mixins.step-container;
    .v-stepper{
        top:0 !important;
        margin-right: 0rem !important;
    }

    .footer {
        position: fixed;
        bottom: 0;
        z-index: 40; 
        width: 100%;
    }

    #vid{
        width: 100%;
        height: 100%;
    }

    .play-icon{
        height: 22px;
        width: 22px;
        margin-bottom: 4px; 
        margin-left: 8px;       
    }

    .btn-link{
        font-size: 2rem;
        font-family: GilroyRegular, Calibri, Helvetica;
    }
    
    .text-conatiner{
        margin-top: 70% !important;
    }

    @media (min-width: 768px){
        .text-conatiner{
            margin-top: 40% !important;
        }
    }
    @media (min-width: 1000px){
        .text-conatiner{
            margin-top: 20% !important;
        }
    }
    .video-counter{
        position: absolute;
        top: 40%;
        span{
            font-size: 10rem;
            font-weight: bold;
        }
    }
    .card{
        border-radius: 1rem;
    }
    .modal-dialog{
        padding: 3rem;
        top: 0 !important; 
        .modal-content {
            border-radius: 3rem !important;
            height: auto !important; 
            .modal-body{
                padding: 2rem 2rem; 
            }
        } 
    }
   
}