.modal-open{
    .modal{
        overflow-y: hidden;
        .modal-height-auto{
            min-height: auto;
        }
        .modal-dialog{
            height: 75%;
            margin: 0 auto;
            top: 25%;
            .modal-content{
                border-radius: 3rem 3rem 0 0;
                height: 100%;
                overflow-y: auto;
                .modal-header{
                    display: none;
                }
                .modal-body{
                    padding: 5rem 2rem;
                    &>p{
                        text-align: justify;
                    }
                }
            }
        }
        .modal-dialog-centered{
            min-height: unset;
        }
        @media(min-width: 768px){
            overflow: auto;
            .modal-dialog{

                height: auto;
                margin: 2rem auto;
                .modal-content{
                    border-radius: .5rem;
                }
            }
        }
    }
}
.modal-open .modal .modal-dialog {

}