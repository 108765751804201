@use './variables' as variables;

@each $name, $color in variables.$color-list{
    #{ '.background-' + $name }{
        background-color: $color !important;    
    }
    #{ '.color-' + $name }{
        color: $color !important;
    }
}

@each $key, $value in variables.$breakpoint-list{
    $infix: '-';
    @if ($key != ''){
        $infix: #{'-' + $key + '-'};
    }
    @media (min-width: #{ $value + 'px'}){
        @each $width in variables.$width-list{
            #{ '.w' + $infix + $width}{
                width: #{ $width + '%' } !important;
            }
            #{ '.h' + $infix + $width + 'vh'}{
                height: #{ $width + 'vh' } !important;
            }
            #{ '.min-h' + $infix + $width }{
                min-height: #{ $width + 'vh'} !important;
            }
        }
        #{ '.cursor' + $infix + 'default' }{
            cursor: default !important;
        }
        #{ '.cancel' + $infix + 'collapsing'}{
            transition: none !important;
            height: auto !important;
            overflow: visible !important;


        }
    }
}

.border-radius{
    border-radius: .5rem !important;
}

.shadow{
    box-shadow: 0 0 .5rem 0 rgba(map-get(variables.$color-list, B800), .16) !important;
}

.index-1100{
    z-index: 1100;
}

.sticky-bottom{
    position: sticky;
    bottom: 0;
    z-index: 1050;
}

.sticky-md-top{
    @media (min-width: 768px){
        position: sticky;
        top: 0;
        z-index: 1050;
    }
}