.vacancy-detail{
    .card{
        border-radius: 3rem 3rem 0 0;
        padding: 2rem;
    }
    @media (min-width: 768px){
        .card{
            border-radius: .5rem;
        }
        .postulation-button-container{
            display: none !important;
        }
    }
    
}