@use "./../abstracts/variables" as variables;
@use "./../abstracts/mixins" as mixins;

.form-control{
    border-radius: .5rem;
    font-size: 2rem;
    height: auto;
    padding: 0.5rem 1.5rem !important;
    display: inline-flex;
    align-items: center;
    border-radius: 40px;
    background: #FFF;
    box-shadow: -5px -5px 10px 0px #E3E3E3 inset, 5px 5px 10px 0px #E9E9E9 inset, 4px 4px 8px 0px #D3D3D3 inset;
    &:focus,
    &:hover{
        border-color: map-get(variables.$color-list, P200 );
    }
    &:focus{
        box-shadow: 0 0 0 .2rem rgba(map-get(variables.$color-list, P200 ), .25);
    }
    color: var(--IRH_SecondaryTurquoise, #0BB9B9);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.5px;
    height: 35px;

}

.form-group{
    position: relative;
    &>label{
        padding-left: 6px;
        font-weight: bold;
        font-size: 1.5rem;
    }
}

.form-check{
    padding-left: 0;
    &,
    label,
    .form-check-inline{
        input[type=radio],
        input[type=checkbox]{
            width: 1px;
            height: 1px;
            overflow: hidden;
            opacity: 0;
            margin-right: 0 !important;
            &:focus{
                &+span.form-check-input{
                    box-shadow: 0 0 0 .2rem rgba(map-get(variables.$color-list, P200 ), .25);
                }
                &:not(:disabled):not(.disabled):not(:checked){
                    &+span.form-check-input:not(.switch){
                        border-color: map-get(variables.$color-list, P200);
                        &:after{
                            position: absolute;
                            content: '\ea66';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: bold;
                            color: map-get(variables.$color-list, P200) !important;
                        }
                    }
                }
            }
            &:checked{
                &:not(:disabled):not(.disabled){
                    &+span.form-check-input{
                        background-color: map-get(variables.$color-list, b300);
                        border-color: map-get(variables.$color-list, P100);
                        &:after{
                            height: 77%;
                            margin-left: -2.7px;
                            position: absolute;
                            content: '\ea66';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: bold;
                            color: map-get(variables.$color-list, B300);
                        }
                        &.switch{
                            &:after{
                                content: '';
                                border-color: map-get(variables.$color-list, P100);
                                background-color: map-get(variables.$color-list, P100);
                                transform: translate(2rem, 0);
                                top: unset;
                                left: unset;
                            }
                        }
                    }
                }
            }
            &:disabled,
            &.disabled{
                &+span.form-check-input{
                    background-color: map-get(variables.$color-list, B500);
                    border-color: map-get(variables.$color-list, B500);
                    &+span{
                        color: map-get(variables.$color-list, S100)
                    }
                }
                &~span{
                    cursor: not-allowed;
                }
            }
        }
        input[type=radio]{
            &+.form-check-input{
                border-radius: 50%;
            }
        }
        span{
            cursor: pointer;
            &.form-check-input{
                border: solid 2px map-get(variables.$color-list, P100); // Directly from Boostrap 4.6
                border-radius: .5rem;
                display: inline-block;
                height: 3rem;
                margin-left: 0;
                margin-right: 1rem;
                margin-top: 0;
                position: relative;
                width: 3rem;
                vertical-align: bottom;
                font-family: Eva-Icons;
                &:hover{
                    border-color: map-get(variables.$color-list, P200);
                }
                &.switch{
                    width: 3.5rem;
                    min-width: 3.5rem;
                    height: 2rem;
                    border-radius: 2rem;
                    position: relative;
                    vertical-align: middle;
                    background-color: map-get(variables.$color-list, B300);
                    &:after{
                        height: 77%;
                        margin-top: 1.4px;
                        margin-left: 2.7px;
                        position: absolute;
                        aspect-ratio: 1/1;
                        background-color: map-get(variables.$color-list, P100);
                        border-radius: 50%;
                        left: unset;
                        transform: translate(0, 0);
                        content: '';
                        transition: transform .2s;
                        border: solid 1px map-get(variables.$color-list, B300);
                    }
                    &+.form-check-input{
                        &+span{
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        &:hover{
            input[type=radio],
            input[type=checkbox]{
                &:not(:disabled):not(.disabled):not(:checked){
                    &+span.form-check-input:not(.switch){
                        border-color: map-get(variables.$color-list, P200);
                        &:after{
                            height: 77%;
                            position: absolute;
                            content: '\ea66';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: bold;
                            color: map-get(variables.$color-list, P200) !important;
                        }
                    }
                }
            }
        }
    }
    .form-check-inline{
        label{
            min-width: 8rem;
        }
        input[type=radio],
        input[type=checkbox]{
            &+label{
                @include mixins.btn-outline-primary;
            }
            &:checked{
                &+label{
                    @include mixins.btn-primary;
                    color: map-get(variables.$color-list, B300);
                }
            }
        }
    }
}

.input-group-append{
    width: 100%;
    .form-control{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }
    .input-group-text{
        padding: 1rem 2rem;
        font-size: 2rem;
    }
}

.ng-autocomplete {
    width: 100% !important;
    .autocomplete-container{
        height: 56px;
        border-radius: 0.5rem !important;
        font-size: 2rem !important;
        height: auto !important;
        padding: 1rem 1.5rem !important;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        box-shadow: none; 
    }
    .suggestions-container{
        left: 0;
        top: 57px;
    }
}


