@use './../abstracts/variables' as variables;
@use './../abstracts/mixins' as mixins;


.areas-item{
    margin-top: -1px;
    border: 1px solid map-get(variables.$color-list, S100);
    .action-btn{
        @include mixins.action-btn;
    }  
}