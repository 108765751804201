@use './../abstracts/variables';

.vacancies-container{
    .filters-container{
        .modal{
            padding-right: 0 !important;
            .modal-dialog-centered{
                min-height: unset;
                .modal-body{
                    padding: 2rem;
                }
            }
        }
        @media (min-width: 768px){
            max-width: 0;
            min-width: 0;
            width: auto;
            transition: all .5s;
            &.shown{
                width: 40rem;
                min-width: 40rem;
                max-width: 40rem;
            }
            .fade:not(.show){
                opacity: 1;
            }
            .modal{
                position: initial;
                overflow: auto;
                display: block !important;
                .modal-dialog{
                    transform: none;
                    min-height: auto;
                    margin: 0 auto;
                    height: 100%;
                    .modal-content{
                        height: 100%;
                        .modal-body{
                        }
                    }
                }
            }
        }
    }
    .list-container{
        .cards-container{
            max-width: 80rem;
            width: 100%;
            .card{
                transition: padding 1s;
            }
        }
    }
    &>router-outlet{
        &+*{
            flex-grow: 1;
        }
    }
}

.tabs-container{
    .tab{
        a{
            color: map-get(variables.$color-list, S200) !important;
            font-family: GilroyRegular;
            &:hover{
                color: map-get(variables.$color-list, P100) !important;
            }
            &.active{
                color: map-get(variables.$color-list, P200) !important;
                font-family: GilroyBold;
                &:hover{
                    color: map-get(variables.$color-list, P300) !important;
                }
            }
        }
    }
}

.menu-container{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1120;
    .btn.btn-link{
        font-family: GilroyRegular;
        color: map-get(variables.$color-list, S300);
        font-size: 3rem;
    }
}

.vacancy-card-background{
    background-image: url(/assets/images/background-pattern.jpg);
    background-size: auto;
    background-position: top left;
    img{
        margin-bottom: -3rem;
    }
}

.chat-container {
    height: calc(100vh);
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
    * {
      white-space: normal;
    }
    #talkjsChatcontainer {
      height: 100%;
      position: relative;
      display: inline-block;
      width: 100%;
      transition: width .5s;
    }
    .vacant-preview-container {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
    }
    &.isShownVacantDetail{
      #talkjsChatcontainer{
        width: 0;
      }
    }
    @media (min-width: 768px) {
        .vacant-preview-container{
            width: 50rem;
        }
        &.isShownVacantDetail{
            #talkjsChatcontainer{
              width: calc(100% - 50rem);
            }
          }
    }
  }