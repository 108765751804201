@use './../abstracts/variables' as variables;

.onboarding-state-container{
    .steps-container{
        position: relative;
        .state-step{
            text-align: center;
            span{
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 1rem;
                background-color: map-get(variables.$color-list, B200);
                display: inline-block;
                vertical-align: middle;
            }
            &:first-child{
                text-align: left;
            }
            &:last-child{
                text-align: right;
            }
            &.active{
                span{
                    background-color: map-get(variables.$color-list, P200);
                }
            }
            &:not(.active){
                p{
                    color: map-get(variables.$color-list, S100)
                }
            }
            @media (min-width: 768px){
                &,
                &:last-child{
                    width: 100%;
                    text-align: left;
                }
            }
        }
        &:after,
        &:before{
            content: '';
            position: absolute;
            height: .25rem;
            background-color: map-get(variables.$color-list, B200);
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            z-index: -1;
        }
        &:before{
            width: 100%;
        }
        &:after{
            background-color: map-get(variables.$color-list, P200);
        }
        &[stage='1']{
            &[step='1']{
                &:after{
                    width: 0%;
                }
            }
            &[step='2']{
                &:after{
                    width: 25%;
                }
            }
            &[step='3']{
                &:after{
                    width: 50%;
                }
            }
            &[step='4']{
                &:after{
                    width: 75%;
                }
            }
            &[step='5']{
                &:after{
                    width: 100%;
                }
            }
        }
        &[stage='2']{
            &[step='1']{
                &:after{
                    width: 0%;
                }
            }
            &[step='2']{
                &:after{
                    width: 20%;
                }
            }
            &[step='3']{
                &:after{
                    width: 40%;
                }
            }
            &[step='4']{
                &:after{
                    width: 60%;
                }
            }
            &[step='5']{
                &:after{
                    width: 80%;
                }
            }
            &[step='6']{
                &:after{
                    width: 100%;
                }
            }
        }
        @media (min-width: 768px){
            .state-step{
                display: flex;
                align-items: center;
            }
            &:after,
            &:before{
                content: '';
                width: .25rem !important;
                height: 100%;
                top: 1.2rem;
                left: .5rem;
                transform: translate(50%, 0);
            }
            &:before{
                height: calc(100% - 2rem);
            }
            &:after{
                background-color: map-get(variables.$color-list, P200);
            }
            &[stage='1']{
                &[step='1']{
                    &:after{
                        height: calc(0% - 2rem);
                    }
                }
                &[step='2']{
                    &:after{
                        height: calc(25% - 2rem);
                    }
                }
                &[step='3']{
                    &:after{
                        height: calc(50% - 2rem);
                    }
                }
                &[step='4']{
                    &:after{
                        height: calc(75% - 2rem);
                    }
                }
                &[step='5']{
                    &:after{
                        height: calc(100% - 2rem);
                    }
                }
            }
            &[stage='2']{
                &[step='1']{
                    &:after{
                        height: calc(0% - 2rem);
                    }
                }
                &[step='2']{
                    &:after{
                        height: calc(20% - 2rem);
                    }
                }
                &[step='3']{
                    &:after{
                        height: calc(40% - 2rem);
                    }
                }
                &[step='4']{
                    &:after{
                        height: calc(60% - 2rem);
                    }
                }
                &[step='5']{
                    &:after{
                        height: calc(80% - 2rem);
                    }
                }
                &[step='6']{
                    &:after{
                        height: calc(100% - 2rem);
                    }
                }
            }
        }
    }
    
    @media (min-width: 768px){
        width: 60rem;
    }
}