@use './../abstracts/variables' as variables;

.my-cv-container{
  tspan{
    font-family: 'GilroyBold';
    font-size: 4.5rem;
  }
  .fixed-container{
    position: fixed;
    z-index: 1;
    top: 33rem;
    right: 0;
    .percentage-container{
      border-radius: 1rem 0 0 1rem;
      width: auto;
      height: 60px;
      position: absolute;
      top: 0;
      right: 0;
      .percentage{
        border-radius: 3rem;
        border: 2px map-get(variables.$color-list, B300 ) solid;
      }
    } 
    .modal-cv{
      background-color: map-get(variables.$color-list, B300);
      border-radius: 1rem;
      .modal-check{
        width: 28px;
        height: 28px;
        position: relative;
        z-index: 10;
        &:after{
          font-family: Eva-Icons;
          position: absolute;
          content: "\ea66";
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          font-weight: bold;
          color: map-get(variables.$color-list, B300);
        }
      }
    }
  }
  .step-container{
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    .step {
      padding: 0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      background-color: cream;
      .circle {
        background-color: map-get(variables.$color-list, P200 );
        border: 2px solid map-get(variables.$color-list, P200 );
        border-radius: 100%;
        width: 8px;    
        height: 8px;
        display: inline-block;
      }
      .v-stepper {
        position: relative;
        top: 40px;
        margin-right: 1rem;
      }
      .line {
        top: 17px;
        left: 3.5px;
        height: 100%;
        position: absolute;
        border-left: 1.8px solid map-get(variables.$color-list, P200 );
      }
      .completed{
        .circle {
          visibility: visible;
          background-color: map-get(variables.$color-list, P200 );
          border-color: map-get(variables.$color-list, P200 );
        }
        .line {
          border-left: 3px solid map-get(variables.$color-list, P200 );
        }
      }
      .active{
        .circle {
          visibility: visible;
          border-color: map-get(variables.$color-list, P200 );
        }
      } 
      .empty {
        .circle {
          visibility: hidden;
        }
        .line {
          top: 0;
          height: 150%;
        }
      }
      &:last-child {
        .line {
        border-left: 3px solid map-get(variables.$color-list, B300);
        z-index: -1;
        }
      }
      
    }
  }
  .edit-button{
    width: 50px;
    height: 50px;
    position: relative;
    margin-left: 80%;
    z-index: 10; 
    input[type=file]{
      display: none;
    }
    &::after{
      font-family: Eva-Icons;
      position: absolute;
      content: "\eab8";
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-weight: bold;
      color: map-get(variables.$color-list, B300);
    }
  }
  .btn-cv{
    border: 1px solid map-get(variables.$color-list, S200);
    opacity: 0.4;
    text-align: left;
    border-radius: 0.5rem;
    height: 50px;
  }
  .h-scroll{
    overflow-x: auto !important;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  .cv-item{
    min-width: auto;
  }
  .profile-image{
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .empty-description{
    line-height: 1;
  }
  .empty-description{
    span{
      text-align: left;
    }
  }

  .section-title{
    font-size: 2.2rem
  }
}

.modal-cv{
  tspan{
      font-family: 'GilroyBold';
      font-size: 4.5rem;
  }
}


image-cropper{
  img{
    max-height: 45vh !important;
    @media (min-width: 768px){
      max-height: 70vh !important;
    }
  }
}
 
  









  