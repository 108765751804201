@use "./../abstracts/variables" as variables;

html{
    font-size: 50%;
    //! Base size: 8px
}

* {
    font-family: GilroyRegular, Calibri, Helvetica;
    font-size: 2rem;
}


@font-face {
    font-family: 'GilroyRegular';
    src: url('/assets/fonts/GilroyRegular.woff2') format('woff2'),
         url('/assets/fonts/GilroyRegular.woff') format('woff'),
         url('/assets/fonts/GilroyRegular.otf') format('otf');
    font-weight: 100;
}

@font-face {
    font-family: 'GilroyBold';
    src: url('/assets/fonts/GilroyBold.woff2') format('woff2'),
         url('/assets/fonts/GilroyBold.woff') format('woff'),
         url('/assets/fonts/GilroyBold.otf') format('otf');
}

@font-face {
    font-family: 'GilroyBlack';
    src: url('/assets/fonts/GilroyBlack.woff2') format('woff2'),
         url('/assets/fonts/GilroyBlack.woff') format('woff'),
         url('/assets/fonts/GilroyBlack.otf') format('otf');
}

h{
    &1{
        font-size: 5rem;
    }
    &2{
        font-size: 4rem;
    }
    &3{
        font-size: 3rem;
    }
    &4,
    &5,
    &6{
        font-size: 2rem;
    }
    &1,
    &2,
    &3,
    &4{
        font-family: GilroyBold;
    }
}
strong{
    font-family: GilroyBold;
}
small{
    font-size: 1.5rem;
}
button.btn{
    font-size: 2rem;
    padding: 0rem 1.5rem;
    height: 3.5rem;
}

a.btn{
    font-size: 2rem;
    padding: 0rem 1.5rem;
    height: 3.5rem;
}
a{
    text-decoration: underline;
    color: map-get(variables.$color-list, P100);
    font-size: 2rem;
    &:hover{
        color: map-get(variables.$color-list, P300);
    }
}

.font-gilroy-regular{
    font-family: GilroyRegular !important;
}

.font-gilroy-bold{
    font-family: GilroyBold !important;
}

.font-gilroy-black{
    font-family: GilroyBlack !important;
}