@use './../abstracts/variables' as variables;
.logo-involve{
    width: 16rem;
    &-small{
        width: 12rem;
    }
    &-extra-small{
        height: 5rem;
    }
}

.profile-picture{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    object-position: center top;
    border-radius: 50%;
}

hr{
    border-top-color: map-get(variables.$color-list, S200);
}

.image-md{
    width: 12rem;
}

ul{
    li{
        &.higlihted,
        &.higlihted::marker{
            color: map-get(variables.$color-list, P200);
        }
    }
}

.form-group-container{
    width: 100%;
    max-width: 60rem;
}

code-input,
.code-input{
    &>span{
        &:first-child{
            padding-left: 0 !important;
        }
        &:last-child{
            padding-right: 0 !important;
        }
    }
    input{
        background-color: darkred;
        font-size: 3rem;
        padding: 2rem 0;
    }
}

.drop-file-area{
    border: dashed 2px map-get(variables.$color-list,  P100);
    border-radius: 1rem;
    transition: all .5s;
    min-height: 10rem;
    flex-direction: column;
    align-items: center;
    .dragging-file,
    .loaded-file{
        display: none;
    }

    &.is-dragging{
        background-color: map-get(variables.$color-list, B100);
        transform: scale(.95);
        justify-content: center;
        .dragging-file{
            display: block;
        }
        .no-file{
            display: none;
        }
    }
    &:not(.is-dragging).has-file{
        justify-content: center;
        .no-file,
        .dragging-file{
            display: none;
        }
        .loaded-file{
            display: block;
        }
    }
}

.loader-image-container{
    background-color: aquamarine;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    padding: 1.5rem;
    border: solid .5rem transparent;
    border-top-color: map-get(variables.$color-list, P200);
    border-radius: 50%;
    animation: spin 3s infinite;
}

.ocr-item{
    &.validated{
        background-color: map-get(variables.$color-list, B200);
        i.eva{
            display: none;
        }
    }
    &:not(.validated){
        cursor: pointer;
    }
}

.sticky-top{
    z-index: 1050;
}

.default-company-logo{
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    object-position: center center;
    &.small{
        width: 5rem;
        height: 5rem;
    }
}

.chat-notification{
    position: relative;
    &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1rem;
        height: 1rem;
        border-radius: .5rem;
        background-color: map-get(variables.$color-list, E600 );
    }
}

.toast {
    opacity: 1;
    width: 55rem !important;
    .toast-image{
        span{
            width: 4rem;
            height: 4rem;
        }
    }
    .toast-content{
        h4,
        p{
            font-size: 1.75rem !important;
        }
        &+button{
            font-size: 3rem !important;
        }
    }
    @media (max-width: 380px) {
        padding: .5rem 1rem !important;
        margin: 0 0 .5rem 2rem;
        width: 99% !important;
    }
}

.email-sent-container{
    top: 0;
    left: 0;
}

input,.input{
    &[type='password']{
        &~.toggle-password-button {
            :not(.show-password){
                display: none;
            }
        }
    }
    &[type='text']{
        &~.toggle-password-button {
            :not(.hide-password){
                display: none;
            }
        }
    }
    &~.toggle-password-button{
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        img{
            margin-right: .5rem;
        }
    }
}

.dropdown-menu.show{
    z-index: 10000;
}

app-search{
    display: flex;
    overflow: hidden;
}