/* You can add global styles to this file, and also import other style files */
@use './app/styles/base/bs-spacing' as bsSpacing;
@use './app/styles/base/eva-icons' as evaIcons;
@use './app/styles/base/reset' as reset;
@use './app/styles/base/typography' as typography;

@use './app/styles/abstracts/animations' as animations;
@use './app/styles/abstracts/helper' as helper;
@use './app/styles/abstracts/mixins' as mixins;
@use './app/styles/abstracts/variables' as variables;
@use './app/styles/components/buttons' as buttons;
@use './app/styles/components/forms' as forms;
@use './app/styles/components/modals' as modals;
@use './app/styles/pages/login' as login;
@use './app/styles/pages/onboarding' as onboarding;
@use './app/styles/pages/my-cv' as cv;
@use './app/styles/pages/vacancy-detail' as vacancyDetail;
@use './app/styles/pages/vacancies' as vacancies;
@use './app/styles/pages/edit-experience' as edit-experience;
@use './app/styles/pages/edit-areas' as edit-areas;
@use './app/styles/pages/video-capture' as video_capture;
@use './app/styles/layout/layout' as layout;

// @use 'themes/'
// @use 'vendors/'


.video{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% - 100px);
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    app-record{
        .preview{
            width: 100%;
            height: 58vh;
            display: block;
            position: initial;
            transform: rotateY(0deg);
        }
        .record{
            width: 100%;
            height: 58vh;
            display: block;
            position: initial;
            transform: rotateY(180deg);
        }
    }
    @media (min-aspect-ratio: 16/9){
        .display-question-container{
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-top: unset;
        }
    }
    @media (min-width: 768px){
        .display-question-container{
            margin-top: -3rem;
        }
    }

}


.involve-ui{
    .input-container{
        position: relative;
        height: 42px;
        .input {
            border-radius: 5px;
            border: 2px solid var(--IRH_PrimaryTurquoise, #6EE3E3);
            position: relative;
            width: 100%;
            height: 41px;
            line-height: 6ex;
            padding: 1rem 1.5rem;
            outline-style: none;
            &.is-invalid{
                border-color: map-get(variables.$color-list, E600 );
            }
        }

        .autocomplete-container{
            border-radius: 5px;
            border: 2px solid var(--IRH_PrimaryTurquoise, #6EE3E3);
            position: relative;
            width: 100%;
            height: 41px !important;
            line-height: 6ex;
            padding: 0rem 1.5rem;
            outline-style: none;
            &.is-invalid{
                border-color: map-get(variables.$color-list, E600 );
            }

            .input-container .x i {
                padding-bottom: 24px;
            }
        }

        .ng-autocomplete .suggestions-container {
            left: 0;
            top: 39px;
        }

        input[role="combobox"]{
            height: 34px !important;
            position: absolute;
            top: -5px;
        }

        .label{
            font-family: GilroyBold;
            position: absolute;
            color: #49454F;
            top: -12.3px;
            z-index: 998;
            left: 1em;
            background-color: white;
            padding: 0 5px;
        }
    }

    .btn-outline-primary{
        width: 200px !important;
        height: 40px !important;
        border-radius: 50px !important;
        border: 2px solid var(--IRH_PrimaryTurquoise, #6EE3E3) !important;
    } 

    .btn{
        max-width: 173px;
        min-width: 173px;
    }

    .toggle-password-button {
        color:#6EE3E3;
        top: -24px;
        right: 4px;
        font-size: 23px;
        i{
            margin-bottom: 1px;
        }
        span{
            font-size: 18px;
        }
        small{
            font-size: 18px;
        }
    }
}

circle-progress{
    tspan{
        font-size: 35px !important;
        color: #838186;
        font-weight: 900;
        font-family: GilroyBold;
    }
}

.modalClass .modal-dialog{
    width: 848px !important;
    max-width: 848px !important;
    height: 509px;
    min-height: 509px !important;
    border-radius: 25px;
    background: #FFF;
    .modal-content{
        background: none;
        border: unset !important;
    }
}

.acceptance-bold-text{
    font-size: 23px !important;
    font-weight: 600 !important;
}

.acceptance-description{
    b{
        font-size: 23px !important;
    }
}


.important-info{
    font-size: 23px !important;
    font-weight: 700 !important;
}

vg-scrub-bar-current-time .background {
    background-color: #6EE3E3 !important;
    height: 9px;
}

vg-overlay-play .vg-icon-play_arrow:before{
    color: #6EE3E3 !important;
    font-size: 60%;
 }

 vg-overlay-play .vg-overlay-play .overlay-play-container.vg-icon-play_arrow {
    pointer-events: none;
    width: 15% !important;
    height: auto !important;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    background: white;
    position: absolute;
    top: 34%;
    left: 42%;
    border-radius: 50%;
    aspect-ratio: 1 / 1 !important;
}

vg-playback-button {
    .button{
        font-size: 2.5rem !important;
    }
    color: black !important;
    font-weight: bolder !important;
}

vg-play-pause, vg-mute, vg-volume, vg-fullscreen {
    color: black !important;
    .icon{
        font-size: 4rem !important;
    }
}


vg-time-display {
    span{
        font-size: 2.5rem !important;
    }
    color: black !important;
    font-weight: bolder !important;
}

vg-time-display {
    span{
        font-size: 2.5rem !important;
    }
    color: black !important;
    font-weight: bolder !important;
}

vg-scrub-bar-buffering-time{
    background-color: gray !important;
    opacity: 0.4 !important;
    height: 9px !important;
}

vg-scrub-bar-current-time{
    height: 9px !important;
    z-index: 1 !important;
}

.presentation-container{
    .record {
        height: 66vh !important;
        margin-top: 2.5rem;
    }
}

vg-volume .volumeBackground{
    background-color: #9e9e9e !important;
}

vg-volume .volumeValue {
    background-color: #6EE3E3 !important;
}

vg-volume .volumeKnob {
    background-color: black !important;
}

.main-header-container{
    position: fixed;
    width: 100%;
    height: 60px;
}
 
.interview-screen{
    min-height: 82vh;
}


.interview-continer .record{
    height: 61vh !important;
}

.sticky-md-top {
    top: 60px;
}

.modal-content{
    border-radius: 20px !important;
}

.step-text{
    font-size: 20px !important;
    b{
        font-size: 20px !important;
    }
}

@media (max-width: 1600px) {
    .acceptance-bold-text{
        font-size: 17px !important;
    }
    .acceptance-description{
        font-size: 17px;
        margin-bottom: 1rem;
        b{
            font-size: 17px !important;
        }
    }
    .important-info{
        font-size: 16px !important;
        font-weight: 700 !important;
    }
    .record-contaier{
        padding-top: 0 !important;
    }
    .presentation-container{
        .record {
            margin-top: 0 !important;
            height: 58vh !important;
        }
    }
    .display-question-container{
        padding-top: 9px !important;
    }
}

.footer-content {
    a{
        color: var(--Black100, #0057FF) !important;
    }
}

.footer{
    img{
        opacity: 0.8;
    }
}

.footer{
    img{
        opacity: 0.8;
    }
}

@media (max-width: 480px) {
    .involve-ui{
        .input-container{
            input[role="combobox"]{
                position: relative;
                top: -13px;
            }
        }
    }
    .date-container{
        width: 100% !important;
    }
    .client-container{
        width: 100% !important;
    }

    .modalClass .modal-dialog {
        width: auto !important;
    }


    .presentation-container{
        .record {
            height: 48vh !important;
        }
    }

    .video{
        padding-top: 0px !important;
    }

    .interview-continer .record{
        top: 50% !important;
    }
    
}


.hidden-bitrix {
    display: none !important;
}

.bitrix-btn{
    &.close-action{
        position: fixed;
        right: 50px;
        bottom: 90px;
        background-color: rgb(221, 72, 72);
        border-radius: 20px;
        color: white;
        height: 21px;
        width: 18px;
        z-index: 99999;
        border-color: white;
        img{
            content: url('/assets/svg/x-white.svg');
            width: 10px;
            height: 10px;
            vertical-align: initial;
            margin-left: -5px;
            margin-bottom: 2px;
        }
    }
    &.show-action{
        position: fixed;
        right: 0px;
        bottom: 10px;
        background-color: #71e1e2;
        border-radius: 10px 0px 0px 10px;
        color: white;
        height: 46px;
        width: 10px;
        z-index: 99999;
        border-color: white;
        img{
            content: url('/assets/svg/chat-white.svg');
            width: 12px;
            height: 12px;
            vertical-align: initial;
            margin-left: -5px;
        }
    }
    
}

.custom-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .selected-option {
    cursor: pointer;
    padding: 5px;
    border: 2px solid var(--IRH_PrimaryTurquoise, #6EE3E3);
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 41px;
  }
  
  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid var(--IRH_PrimaryTurquoise, #6EE3E3);
    width: 100%;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 1000;
  }
  
  .dropdown-list li {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .dropdown-list li:hover {
    background-color: #f0f0f0;
  }
