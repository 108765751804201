@keyframes fadeIn {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.fade-in{
    animation: fadeIn .5s;
}

@keyframes spin{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes deletesItem {
    0%{
        max-height: 15rem;
    }
    100%{
        max-height: 0;
        padding: 0 !important;
        display: none;
    }
}

.deleting{
    overflow: hidden;
    animation: deletesItem 1s;

}