@use "./../abstracts/variables" as variables;
$sizes: 0 0, 1 .5, 2 1, 3 2, 4 3, 5 4;

@each $key, $value in variables.$breakpoint-list{
    $infix: '-';
    @if ($key != ''){
        $infix: #{'-' + $key + '-'};
    }
    @media (min-width: #{ $value + 'px'}){
        @each $classSize, $classValue in $sizes{
            // Padding
            #{'.p' + $infix + $classSize}{
                padding: #{$classValue + 'rem'} !important;
            }
            #{'.px' + $infix + $classSize},
            #{'.pl' + $infix + $classSize}{
                padding-left: #{$classValue + 'rem'} !important;
            }
            #{'.px' + $infix + $classSize},
            #{'.pr' + $infix + $classSize}{
                padding-right: #{$classValue + 'rem'} !important;
            }
            #{'.py' + $infix + $classSize},
            #{'.pt' + $infix + $classSize}{
                padding-top: #{$classValue + 'rem'} !important;
            }
            #{'.py' + $infix + $classSize},
            #{'.pb' + $infix + $classSize}{
                padding-bottom: #{$classValue + 'rem'} !important;
            }
            // Margin
            #{'.m' + $infix + $classSize}{
                margin: #{$classValue + 'rem'} !important;
            }
            #{'.mx' + $infix + $classSize},
            #{'.ml' + $infix + $classSize}{
                margin-left: #{$classValue + 'rem'} !important;
            }
            #{'.mx' + $infix + $classSize},
            #{'.mr' + $infix + $classSize}{
                margin-right: #{$classValue + 'rem'} !important;
            }
            #{'.my' + $infix + $classSize},
            #{'.mt' + $infix + $classSize}{
                margin-top: #{$classValue + 'rem'} !important;
            }
            #{'.my' + $infix + $classSize},
            #{'.mb' + $infix + $classSize}{
                margin-bottom: #{$classValue + 'rem'} !important;
            }

        }
    }
}