@use "./variables" as variables;

@mixin background-color($variable-name){
    .#{"background-" + $variable-name}{
        background-color: #{$variable-name} !important;
    }
}

@mixin btn{
    font-size: 1.75rem;
    padding: 1rem 1.5rem;
    font-family: GilroyBold;
}

@mixin btn-primary{
    border: none;
    background:  linear-gradient(317deg, rgba(12,154,154,1) 0%, rgba(8,210,210,1) 61%, rgba(255,255,255,1) 100%);
    box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
    filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50)) ;
    border-radius: 3rem;
    &:not(:disabled):not(.disabled):hover{
      color: white;
      scale: 1.05;
    }
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active{
      color: rgba(12,154,154,1);
      box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
      filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50)) ;
    }
    &.disabled,
    &:disabled{
      color: rgba(12,154,154,1);
      background: var(--inactive-button, linear-gradient(331deg, #D6D6D6 1.02%, #FFF 98.06%));
      box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
      filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50));
    }
}

@mixin btn-danger{
  background-color: map-get(variables.$color-list, E600);
  border-color: map-get(variables.$color-list, E600);
  border-radius: 3rem;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active{
      background-color: map-get(variables.$color-list, E700);
      border-color: map-get(variables.$color-list, E700);
  }
  &.disabled,
  &:disabled{
      background-color: map-get(variables.$color-list, B600);
      border-color: map-get(variables.$color-list, B600);
      color: map-get(variables.$color-list, S200);
  }
}

@mixin btn-outline-primary{
  a{
    text-decoration: none;
  }
  border: none;
  color: rgba(12,154,154,1);
  background: var(--inactive-button, linear-gradient(331deg, #D6D6D6 1.02%, #FFF 98.06%));
  box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
  filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50));
    border-radius: 3rem;
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active{
      color: rgba(12,154,154,1);
      background: var(--inactive-button, linear-gradient(331deg, #D6D6D6 1.02%, #FFF 98.06%));
      box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
      filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50));
    }
    &.disabled,
    &:disabled{
      color: rgba(12,154,154,1);
      background: var(--inactive-button, linear-gradient(331deg, #D6D6D6 1.02%, #FFF 98.06%));
      box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
      filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50));
    }
    &:not(:disabled):not(.disabled):hover{
      color: #FFF;
      background:  linear-gradient(317deg, rgba(12,154,154,1) 0%, rgba(8,210,210,1) 61%, rgba(255,255,255,1) 100%);
      box-shadow: -4px -4px 4px 0px rgba(119, 192, 188, 0.10) inset, 0px 4px 4px 0px rgba(226, 255, 255, 0.50) inset;
      filter: drop-shadow(-10px -10px 20px #FFF) drop-shadow(10px 10px 20px rgba(210, 210, 210, 0.50)) drop-shadow(5px 5px 15px rgba(165, 165, 165, 0.25)) drop-shadow(-5px -5px 10px rgba(255, 255, 255, 0.50)) ;
    }
}

@mixin btn-outline-danger{
  color: map-get(variables.$color-list, E600);
  border-color: map-get(variables.$color-list, E600);
  border-radius: 3rem;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active{
      background-color: map-get(variables.$color-list, E600);
      color: map-get(variables.$color-list, B300);
      border-color: map-get(variables.$color-list, E600);
  }
  &.disabled,
  &:disabled{
      color: map-get(variables.$color-list, B600);
      border-color: map-get(variables.$color-list, B600);
  }
}

@mixin action-btn{
    height: 33px;
    padding: 0.6rem;
    border-radius: 5px !important;
    &.edit{
        background-color: map-get(variables.$color-list, B200);
        border-color: map-get(variables.$color-list, B200);
        &:hover{
            background-color: map-get(variables.$color-list, B200) !important;
            border-color: map-get(variables.$color-list, B200) !important;
        }
        .eva{
            &::before{
                color: map-get(variables.$color-list , P200 );
                font-size: 22px;
            }
        }
    }
    &.delete{
        background-color: map-get(variables.$color-list, E500);
        border-color: map-get(variables.$color-list, E500);
        &:hover{
            background-color: map-get(variables.$color-list, E500) !important;
            border-color: map-get(variables.$color-list, E500) !important;
        }
        .eva{
            &::before{
                color: map-get(variables.$color-list , E600);
                font-size: 22px;
            }
        }
    }
}

@mixin step-container{
    .step-container{
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        .step {
          padding: 0px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          background-color: cream;
          .circle {
            background-color: map-get(variables.$color-list, P200 );
            border: 2px solid map-get(variables.$color-list, P200 );
            border-radius: 100%;
            width: 8px;    
            height: 8px;
            display: inline-block;
          }
          .v-stepper {
            position: relative;
            top: 40px;
            margin-right: 1rem;
          }
          .line {
            top: 17px;
            left: 3.5px;
            height: 100%;
            position: absolute;
            border-left: 1.8px solid map-get(variables.$color-list, P200 );
          }
          .completed{
            .circle {
              visibility: visible;
              background-color: map-get(variables.$color-list, P200 );
              border-color: map-get(variables.$color-list, P200 );
            }
            .line {
              border-left: 3px solid map-get(variables.$color-list, P200 );
            }
          }
          .active{
            .circle {
              visibility: visible;
              border-color: map-get(variables.$color-list, P200 );
            }
          } 
          .empty {
            .circle {
              visibility: hidden;
            }
            .line {
              top: 0;
              height: 150%;
            }
          }
          &:last-child {
            .line {
            border-left: 3px solid map-get(variables.$color-list, B300);
            z-index: -1;
            }
          }
          
        }
      }
}



