@use "./../abstracts/variables" as variables;
@use "./../abstracts/mixins" as mixins;

.btn{
    @include mixins.btn;
    &.btn-primary{
        @include mixins.btn-primary;
    }
    &.btn-danger{
        @include mixins.btn-danger;
    }
    &.btn-link{
        color: map-get(variables.$color-list, P100);
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active{
            color: map-get(variables.$color-list, P300);
            text-decoration: underline;
        }
        &.disabled,
        &:disabled{
            color: map-get(variables.$color-list, B600);
        }
        &:focus{
            box-shadow: 0 0 0 .2rem rgba( map-get(variables.$color-list, P200), .25 );
        }
        &.btn-no-decoration{
            &,
            &:hover,
            &:active,
            &:focus{
                text-decoration: none !important;
            }
        }
    }
    &.btn-outline-primary{
        @include mixins.btn-outline-primary;
    }
    &.btn-outline-danger{
        @include mixins.btn-outline-danger;
    }
    &.btn-min-width{
        @media (min-width: 768px){
            min-width: 20rem;
        }
    }
    &.disabled,
    &:disabled{
        opacity: 1;
    }

}